import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { pageList } from "../../../common/authorization";
import "./appbarStyle.css";
import { logout } from "../../../store/slices/authSlice";
import { doSmth } from "../../../db/examMethods";
import { deepOrange } from "@mui/material/colors";
import Drawer from "@mui/material/Drawer";
import { useHistory } from "react-router-dom";

const logo = (
  <Link to="/">
    <img className="logo" src="/assets/images/tzv-logo.png" alt="Logo" />
  </Link>
);

export const AppBar = ({ user, logout }) => {
  const history = useHistory();
  const [isAvatarDrawerOpen, setAvatarDrawerState] = useState(false);
  const doSmthButton = user.uid &&
    [
      "iHw8zA3cddQYZUkimhOzErQkM5s1",
      "WW1sajDgs9ZGtzQa117K3jzjOig2",
      "bDyy9Z8AkiZEijYb8lkbd0pldpr1",
      "jxqJk4aZpbMfNUYhLMCYlpgSv972",
      "PBDhA8VWovgVPPsiSYBEHkefWg92",
      "02WYCQusl9OYREwe6vQyC5xh3FK2"
    ].includes(user.uid) && (
      <Button
        color="primary"
        variant="contained"
        style={{ textTransform: "none" }}
        onClick={() => doSmth()}
      >
        {"Bişeyler Yap"}
      </Button>
    );

  const goToHomePage = () => {
    history.push("/");
  };
  const loginButton = (
    <Link style={{ textDecoration: "none" }} to={pageList.login.path}>
      <Button color="secondary" variant="contained">
        {"GİRİŞ"}
      </Button>
    </Link>
  );

  const registerButton = (
    <Link style={{ textDecoration: "none" }} to={pageList.signup.path}>
      <Button color="primary" variant="contained">
        {"KAYIT OL"}
      </Button>
    </Link>
  );

  const logoutButton = (
    <Button
      color="primary"
      variant="contained"
      style={{ textTransform: "none" }}
      onClick={() => logout(goToHomePage)}
    >
      {"ÇIKIŞ"}
    </Button>
  );
  const toggleAvatarDrawer = () => setAvatarDrawerState(!isAvatarDrawerOpen);
  const avatarDrawer = (
    <Drawer
      open={isAvatarDrawerOpen}
      anchor={"right"}
      onClose={toggleAvatarDrawer}
    >
      <div className="avatarDrawer">
        <div className="drawerUpperBox">
          <div className="drawerNameContainer">
            <span>{user.name}</span>
            <span>{user.email}</span>
          </div>
        </div>

        {logoutButton}
      </div>
    </Drawer>
  );

  const avatar = (
    <Avatar
      sx={{ bgcolor: deepOrange[500], cursor: "pointer" }}
      onClick={toggleAvatarDrawer}
    >
      {user?.name?.substring(0, 1)?.toLocaleUpperCase()}
    </Avatar>
  );

  const allExamsButton = (
    <Link style={{ textDecoration: "none" }} to={pageList.examStorePage.path}>
      <Button color="primary" variant="contained">
        {"TÜM SINAVLAR"}
      </Button>
    </Link>
  );

  const purchasedExamsButton = (
    <Link style={{ textDecoration: "none" }} to={pageList.userExams.path}>
      <Button color="primary" variant="outlined">
        {"SINAVLARIM"}
      </Button>
    </Link>
  );

  const createdExamsButton = user.role === "examManager" && (
    <Link
      style={{ textDecoration: "none" }}
      to={pageList.userCreatedExams.path}
    >
      <Button color="primary" variant="contained">
        {"OLUŞTURULAN SINAVLAR"}
      </Button>
    </Link>
  );

  const questionBankButton = user.role === "examManager" && (
    <Link style={{ textDecoration: "none" }} to={pageList.userQuestions.path}>
      <Button color="primary" variant="contained">
        {"SORU BANKASI"}
      </Button>
    </Link>
  );

  const anonimUserButtons = !user.uid && (
    <div>
      {/* {allExamsButton} */}
      {loginButton}
      {/* {registerButton} */}
    </div>
  );

  const authenticatedUserButtons = user.uid && (
    <div className="authenticatedUserButtonsBox">
      {/* {doSmthButton} */}
      {/* {allExamsButton} */}
      {questionBankButton}
      {createdExamsButton}
      {/* {purchasedExamsButton} */}
      {avatar}
      {avatarDrawer}
    </div>
  );

  console.log('user :>> ', user);

  return (
      <div className="appBarBox">
        {logo}
        {user.uid ? authenticatedUserButtons : anonimUserButtons}
      </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    //signInWithEmailAndPass: (email, pass) => dispatch(fetchUser({email, pass})),
    //dispatch(signIn(email, pass)),
    logout: (callback) => {
      dispatch(logout());
      callback();
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
