import React, { Component, createRef } from "react";
import { Button, CircularProgress, TextField } from "@mui/material";
import { HomeTemplate } from "../../components/templates/homeTemplate/homeTemplate";
import {
  fetchExam,
  getAnswerForms,
  getQuestions,
  getExamQuestionsByExamId,
  releaseExamAnswers,
  calculateExamQuestionsScores,
  calculateUserExamTotalScores,
  calculateUserExamTotalScoresKanguru,
  setScoreToExamQuestions,
  publishExamResults,
  calculateUserExamTotalScoresTZV,
  downloadLogs,
} from "../../db/examMethods";
import ReactExport from "react-export-excel";
import { FormControlLabel, Grid, Checkbox } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

/**
 Bu sayfa bizim sınavlar sonrasında, sınavların doğru cevaplarını yayınladığımız veya sınava girenlerin verdiği cevapları
 çektiğimiz ve bu cevapları çektikten sonra direkt excele aktardığımız veya tüm sonuçları kimin ne kadar puan aldığını sonuçlar
 hazırlayıp sonrasında excele aktardığımız, Soru puanlarını hesaplattığımız ve Katılımcıların puanlarını hesaplattığımız sayfa.
 Buraya dikkat kimlerin ne kadar puan aldığını da burdan çekebiliyoruz
 verileri çek, sonuçları hazırla, puanları excele aktar
 */
export class ExamResultPage extends Component {
  questionsSameScoreInputRef = createRef();
  publishScoreCheckboxRef = createRef();
  publishRankCheckboxRef = createRef();
  publishParticipantsCheckboxRef = createRef();
  downloadFormsAsExcelButtonRef = createRef();
  studentIdInputForLogRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      pullAnswerFormsClicked: false,
      dataFetched: false,
      isFetchingFormsForExcel: false,
      isFetchingFormsForExcelDone: false,
      resultsReady: false,
      onProgress: false,
      exam: {},
      index: null,
      total: null,
    };

    this.exam_questions = {};
    this.clmns = [];
    this.resultClmns = [];
    this.answerForms = [];
    this.resultForms = [];
    this.examId = this.props.match.params.exam_id;
  }

  componentDidMount() {
    this.fetchExamData();
  }

  fetchExamData = async () => {
    const exam = await fetchExam(this.examId);
    this.exam_questions = await getExamQuestionsByExamId(this.examId);
    if (!exam.id && !this.exam_questions.id) alert("Sınav bilgisi çekilemedi!");
    else {
      this.setState({ exam });
    }
  };

  prepareExcelData = async () => {
    this.setState({
      dataFetched: false,
      pullAnswerFormsClicked: true,
      isFetchingFormsForExcel: true,
    });

    this.answerForms = [];
    let { examId } = this;
    const forms = await getAnswerForms(examId);

    const examQuestions = await getQuestions({ exam_id: examId });
    this.clmns = [
      { id: "user_id", text: "KİŞİ" },
      { id: "rank", text: "Sıralama" },
      { id: "total_score", text: "Puan" },
      { id: "total_score_additional", text: "Ek Puan" },
      { id: "total_score_additional2", text: "Ek Puan-2" },
      { id: "last_response_updated_at", text: "Son Cevap Güncelleme Tarihi" },
    ];
    examQuestions.forEach((q) => {
      if (q.type)
        // type'ı olmayan ya da 0 olanlar açıklama sorusudur. Cevabı yoktur.
        this.clmns.push({ text: q.title, id: q.id });
    });

    forms.forEach((f) => {
      let rowData = {
        user_id: f.user_id,
        rank: f.rank,
        total_score: f.total_score,
        total_score_additional: f.total_score_additional,
        total_score_additional2: f.total_score_additional2,
        last_response_updated_at: f.last_response_updated_at?.toDate(),
        question_title: f.question_title,
      };
      examQuestions.forEach((q) => {
        rowData[q.id] = f[q.id]?.response?.trim() || "";
      });
      this.answerForms.push(rowData);
    });
    this.setState({
      dataFetched: true,
      pullAnswerFormsClicked: false,
      isFetchingFormsForExcel: false,
      isFetchingFormsForExcelDone: true,
    });

    this.downloadFormsAsExcelButtonRef.current.click();
  };

  downloadAnswerForms = () => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const {
      dataFetched,
      exam,
      isFetchingFormsForExcelDone,
      isFetchingFormsForExcel,
    } = this.state;
    if (isFetchingFormsForExcelDone)
      return (
        <ExcelFile
          filename={exam.description}
          element={
            <input
              type="button"
              ref={this.downloadFormsAsExcelButtonRef}
              onClick={() => {
                this.setState({ isFetchingFormsForExcelDone: false });
              }}
            ></input>
          }
        >
          <ExcelSheet data={this.answerForms} name={"Sonuçlar"}>
            {this.clmns.map((cc) => (
              <ExcelColumn label={cc.text} value={(row) => row[cc.id]} />
            ))}
          </ExcelSheet>
        </ExcelFile>
      );
    else
      return (
        <Button
          variant="contained"
          color="secondary"
          onClick={this.prepareExcelData}
          disabled={isFetchingFormsForExcel}
        >
          {isFetchingFormsForExcel
            ? "Veriler hazırlanıyor..."
            : "Verileri Excel'e Aktar"}
        </Button>
      );
  };

  onReleaseExamAnswerButtonClick = async () => {
    if (
      window.confirm(
        "DİKKAT!! Bu butona bastığınızda tüm katılımcılar sınava ait doğru cevapları görebilir. Emin misiniz???"
      ) == true
    ) {
      await releaseExamAnswers(this.exam_questions);
      this.exam_questions = await getExamQuestionsByExamId(this.examId);
      alert("Cevaplar yayınlandı.");
    } else {
    }
  };

  renderReleaseExamAnswerButton = ({ disabled }) => {
    return (
      <Button
        variant="contained"
        color="secondary"
        disabled={disabled}
        onClick={this.onReleaseExamAnswerButtonClick}
      >
        Cevap Anahtarını Yayınla
      </Button>
    );
  };

  onRenderCalculateEachQuestionScoreButtonClick = async () => {
    await calculateUserExamTotalScoresTZV(this.examId);
    this.exam_questions = await getExamQuestionsByExamId(this.examId);
  };

  renderCalculateEachQuestionScoreButton = () => {
    return (
      <Button
        disabled
        variant="contained"
        color="secondary"
        onClick={this.onRenderCalculateEachQuestionScoreButtonClick()}
      >
        Sorulara Değişken Puan Ver ve Yayınla
      </Button>
    );
  };

  onProgressCallback = (index, total) => {
    if (index === total) {
      this.setState({ index, total, onProgress: false });
    } else {
      this.setState({ index, total });
    }
  };

  onCalculateUsersTotalScoresButtonClick = async () => {
    let { examId } = this;
    this.setState({ onProgress: true, dataFetched: true });
    await calculateUserExamTotalScores(examId, this.onProgressCallback);
  };

  onCalculateUsersTotalScoresButtonClickKanguru = async () => {
    let { examId } = this;
    this.setState({ onProgress: true, dataFetched: true });
    await calculateUserExamTotalScoresKanguru(examId, this.onProgressCallback);
  };

  onPublishResultsButtonClick = async () => {
    let { examId } = this;
    this.setState({ onProgress: true, dataFetched: true });
    await publishExamResults(
      examId,
      this.onProgressCallback,
      this.publishScoreCheckboxRef.current.checked,
      this.publishRankCheckboxRef.current.checked,
      this.publishParticipantsCheckboxRef.current.checked
    );
  };

  renderCalculateUsersTotalScoresButton = ({ disabled }) => {
    return (
      <Button
        disabled={disabled}
        variant="contained"
        color="secondary"
        onClick={this.onCalculateUsersTotalScoresButtonClick}
      >
        Katılımcı Sonuçlarını Hesapla
      </Button>
    );
  };

  renderCalculateUsersTotalScoresButtonKanguru = ({ disabled }) => {
    return (
      <Button
        disabled={disabled}
        variant="contained"
        color="secondary"
        onClick={this.onCalculateUsersTotalScoresButtonClickKanguru}
      >
        Katılımcı Sonuçlarını Hesapla (Boşlar +1 Puan)
      </Button>
    );
  };

  renderPublishResultsButton = ({ disabled }) => {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={this.onPublishResultsButtonClick}
        >
          Sonuçları Tüm Kullanıcılara Yayınla
        </Button>
        <FormControlLabel
          control={<Checkbox inputRef={this.publishRankCheckboxRef} />}
          label="Sıralamayı Göster"
        />
        <FormControlLabel
          control={<Checkbox inputRef={this.publishScoreCheckboxRef} />}
          label="Puanları Göster"
        />
        <FormControlLabel
          control={<Checkbox inputRef={this.publishParticipantsCheckboxRef} />}
          label="Katılımcı Sayısını Göster"
        />
      </>
    );
  };

  downloadLogsButton = () => {
    return (
      <div>
        <TextField
          fullWidth
          id="outlined-basic"
          size="small"
          label="User-id (excel dosyasında yazmaktadır.)"
          inputRef={this.studentIdInputForLogRef}
          style={{ marginBottom: 12 }}
        />

        <Button
          variant="contained"
          color="secondary"
          onClick={async () => {
            const studentId = this.studentIdInputForLogRef.current.value;
            await downloadLogs(studentId, this.examId);
          }}
        >
          Log'ları İndir
        </Button>
      </div>
    );
  };

  renderSetScoreToAllQuestionsOfTozos = ({ disabled }) => {
    return (
      <div>
        <Button
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={async () => {
            const score = this.questionsSameScoreInputRef.current.value;
            await calculateExamQuestionsScores(this.exam_questions);
          }}
        >
          TZV Sorularına Dinamik Puan Ver
        </Button>
      </div>
    );
  };

  renderSetScoreToAllQuestions = () => {
    return (
      <div key={this.exam_questions}>
        <Button
          disabled
          variant="contained"
          color="secondary"
          onClick={async () => {
            const score = this.questionsSameScoreInputRef.current.value;
            await setScoreToExamQuestions(parseInt(score), this.exam_questions);
          }}
        >
          Tüm Sorulara Eşit Puan Ver ve Yayınla
        </Button>

        <TextField
          type="number"
          style={{ marginTop: 12 }}
          size="small"
          inputRef={this.questionsSameScoreInputRef}
          label="Lütfen puan giriniz..."
          InputProps={{
            inputProps: {
              min: 1,
            },
          }}
        />
      </div>
    );
  };

  renderPullAllDataButton = () => {
    const { pullAnswerFormsClicked, dataFetched } = this.state;

    return (
      <Button
        disabled={dataFetched}
        variant="contained"
        color="secondary"
        onClick={this.prepareExcelData}
      >
        {pullAnswerFormsClicked && (
          <CircularProgress style={{ width: 20, height: 20, marginRight: 3 }} />
        )}
        {pullAnswerFormsClicked ? "Hazırlanıyor..." : "Verileri çek"}
      </Button>
    );
  };

  render() {
    const {
      dataFetched,
      resultsReady,
      pullAnswerFormsClicked,
      exam,
      onProgress,
      index,
      total,
    } = this.state;
    const currentDate = new Date();
    const examEndDate = new Date(exam.end_date);
    const isDisabled = currentDate - examEndDate > 15 * 24 * 60 * 60 * 1000;

    let content2 = (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TableContainer component={Paper} style={{ marginTop: 24 }}>
          <b style={{ fontSize: 22 }}>{exam.header}</b>
          <TableHead style={{ display: "flow-root", fontSize: 20 }}>
            {exam.description}
          </TableHead>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            style={{ marginTop: 24 }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  {this.renderReleaseExamAnswerButton({ disabled: isDisabled })}
                </TableCell>
                <TableCell>
                  {
                    "Sınava ait cevap anahtarını tüm kullanıcılara yayınlar. Her sorunun altında sorunun doğru cevabı yazacaktır. Bu butonun işe yaraması için sorulara doğru cevap bilgisinin girilmiş olması gerekmektedir. Katılımcılar doğru çözdükleri soruların kutularını yeşil renkte diğerlerini gri renkte görür."
                  }
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  {this.renderSetScoreToAllQuestionsOfTozos({
                    disabled: isDisabled,
                  })}
                </TableCell>
                <TableCell>
                  {
                    "TZV Sınavları için her soruya çözülme yüzdesine göre puan verir. (10-20 arası). Soru puanları öğrencilerin ekranında yayınlanır. Bu butona bastıktan sonra 'KATILIMCI SONUÇLARINI HESAPLA' butonuna basmanız mantıklı olacaktır."
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {this.renderSetScoreToAllQuestions({ disabled: isDisabled })}
                </TableCell>
                <TableCell>
                  {
                    "Bu sınavdaki tüm sorulara soldaki kutucuğa girilen puanı verir. Soru puanları öğrencilerin ekranında yayınlanır. Bu butona bastıktan sonra 'KATILIMCI SONUÇLARINI HESAPLA' butonuna basmanız mantıklı olacaktır."
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {this.renderCalculateUsersTotalScoresButton({
                    disabled: isDisabled,
                  })}
                </TableCell>
                <TableCell>
                  {
                    "Katılımcıların toplam puanlarını hesaplar ama yayına almaz. Bu puanları excel dosyasına indirerek kontrol edebilirsiniz. (Bu butonun doğru çalışabilmesi için her sorunun doğru cevabı ve puanının kaytılı olması gerekmektedir.)"
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {this.renderCalculateUsersTotalScoresButtonKanguru({
                    disabled: isDisabled,
                  })}
                </TableCell>
                <TableCell>
                  {
                    "Üstteki butonla aynı işlevi yapar, fakat boş bırakanlara +1 puan verir. Ayrıca, negatif puanlı sorular için istisnasız tüm öğrencilere tam puan verir. (-4 ise +4 verir.)"
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {this.downloadAnswerForms({ disabled: isDisabled })}
                </TableCell>
                <TableCell>
                  {
                    "Sınava katılan tüm kullanıcıların cevaplarını ve puanlarını indirir."
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {this.downloadLogsButton({ disabled: isDisabled })}
                </TableCell>
                <TableCell>
                  {
                    "Yarışmacı id girerek öğrencinin bu sınavdaki tüm cevap kayıt loglarını görebilirsiniz. (Yarışmacı id excel dosyasında yazmaktadır.)"
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {this.renderPublishResultsButton({ disabled: isDisabled })}
                </TableCell>
                <TableCell>
                  {
                    "Hesaplanmış puanları excele aktarıp gerekli kontrolleri yaptıysanız artık bu butona basarak öğrenci ekranlarında yayına alabilirsiniz."
                  }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TableContainer>
          <Table>
            <TableRow >
                <TableCell >{this.renderPullAllDataButton()}</TableCell>
                <TableCell >{"Aşağıdaki butonların çalışabilmesi için önce sınava giren herkesin cevap formunun 1 kez çekilmesi gerekmektedir. Veriler çekildikten sonra excele aktarma, total puan hesaplama vb. işlemler yapılabilir."}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell >{this.renderCalculateEachQuestionScoreButton()}</TableCell>
                <TableCell >{"Soruların doğru çözülme yüzdesine bakarak puan hesaplaması yapar. Her soruya 10 puan + çözülme yüzdesine göre 0-10 arası puan belirler ve bu puanları kullanıcıların ekranında yayınlar. (Bu buton TZV sınavları içindir.)"}</TableCell>
              </TableRow>
          </Table>
        </TableContainer> */}

        {onProgress && <div>{`Hesaplanan: ${index}, Toplam: ${total} `}</div>}
      </div>
    );

    return <HomeTemplate content={content2} />;
  }
}

export default ExamResultPage;
